$(document).on('turbolinks:load', function() {
  let $speakersList = $('.js-speakers-list');
  let $speakers = $speakersList.children('.js-speaker-preview');

  $(".js-speakers-sort").click(function() {
    let [type, directionTxt] = $(this).data('sorttype').split(" ");
    let direction = (directionTxt === 'asc') ? 1 : -1;

    console.log(direction);

    $speakers.detach().sort(function(a, b) {
      var aAttr = $(a).data(type);
      var bAttr = $(b).data(type);
      return direction * ((aAttr > bAttr) ? (aAttr > bAttr) ? 1 : 0 : -1);
    });
    $speakersList.append($speakers);
  });
});
