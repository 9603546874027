//
// Main.js
//

var input_auto_submit;

function submitClosestForm (that) {
  that.closest('form').submit()
};

// Equalize blocks

$(document).on('turbolinks:load', function () {
  $('.card__content').matchHeight();
});

// Cutom select

$(document).on('turbolinks:load', function () {
  select2 = $(".custom-select2");

  select2.select2({ minimumResultsForSearch: -1 });
  select2.on('select2:opening', function () { clearTimeout(input_auto_submit) });
  select2.on('select2:select', function () { submitClosestForm($(this)) });

  $('.input_auto_submit').on('input', function (e) {
    that = $(this);
    clearTimeout(input_auto_submit);
    input_auto_submit = setTimeout(function () { submitClosestForm(that) }, 800);
  })
});

$(document).on('turbolinks:before-cache', function () {
  $(".custom-select2").select2('destroy');
});