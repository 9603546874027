function generateFilePreview(input) {
  if (input.files && input.files[0]) {
    const firstFile = input.files[0]

    function createPreviewImage(file) {
      let img = input.parentNode.querySelector('img.file-preview');
      if (img) img.remove()
      if (!['image/png', 'image/jpg', 'image/gif', 'image/svg+xml'].includes(file.type)) {
        return
      }

      img = new Image();
      img.className = 'file-preview'
      img.dataset.previewFor = input.id
      img.src = URL.createObjectURL(file);
      img.onload = function() {
        URL.revokeObjectURL(img.src)
      }
      input.parentNode.insertBefore(img, input.nextSibling)
    }
    
    createPreviewImage(firstFile)

    const fileOuter = input.parentNode.querySelector('span.file-custom-outer')
    const fileCustom = fileOuter.querySelector('span.file-custom')
    fileCustom.dataset.selectedFileName = firstFile.name
    fileCustom.classList.add('file-selected')
  }
}
document.addEventListener("turbolinks:load", function () {
  const fileInput = document.querySelector('input.form__control_file, input.form-control-file');
  if (fileInput) {
    fileInput.addEventListener('change', (event) => { 
      generateFilePreview(event.target)
    });
  }
});
