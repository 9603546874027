document.addEventListener("turbolinks:load", function () {
  var getTicketsBtns = document.getElementsByClassName("get_tickets_btn");
  for (var i = 0; i < getTicketsBtns.length; i++) {
    getTicketsBtns[i].addEventListener("click", function (e) {
      document.body.classList.add('tickets-sidebar-visible');
      e.preventDefault();
    });
  }

  document.getElementById("cancel_tickets_overlay_btn").addEventListener("click", function (e) {
    document.body.classList.remove('tickets-sidebar-visible');
    e.preventDefault();
  });

  document.addEventListener("turbolinks:before-cache", function () {
    if (!document.body.classList.contains('hidden')) {
      document.body.classList.remove('tickets-sidebar-visible');
    }
  })
});

