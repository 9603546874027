// Create a class for the element
class PodcastEpisodePlayer extends HTMLElement {
  constructor() {
    super();
    this.iframeSrc;
    this.attachShadow({ mode: 'open' });
    this.isVisible = false;
    this.shadowRoot.innerHTML = `
        <style>
          :host {
            border: 2px solid #3d0551;
            background: #fff;
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          :host:before {
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 9px;
            border-color: transparent transparent transparent #3d0551;
            position: absolute;
            top: calc(50% - 6px);
            left: calc(50% - 5px);
          }
          :host(:focus),
          :host(:hover),
          :host([visible]) {
            background: #3d0551;
            border: 2px solid #ff6c6c;
          }
          :host(:focus):before,
          :host(:hover):before,
          :host([visible]):before {
            border-color: transparent transparent transparent #ff6c6c;
          }
          :host([visible]) #player {
              opacity: 1;
              pointer-events: all;
          }

          :host([visible]) #player {
              bottom: 0;
          }

          #player {
              position: fixed;
              bottom: 0;
              left: 0;
              width: 100%;
              min-height: 20px;
              z-index: 100;
              color: #fff;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              opacity: 0;
              pointer-events: none;
              transition: all 0.3s ease-out;
          }

          ::slotted {
            display: none
          }
      </style>
      <div id="player">
      </div>
    `;

    this.playerContainer = this.shadowRoot.querySelector('#player')
  }

  attributeChangedCallback(_name, _oldValue, _newValue) {
    this.isVisible = this.hasAttribute('visible')
  }
  static get observedAttributes() {
    return ['visible'];
  }

  connectedCallback() {
    if (this.hasAttribute('data-src')) {
      this.iframeSrc = this.getAttribute('data-src');
    }
  }

  createIframe() {
    const iframe = `
      <iframe width="100%" height="180" frameborder="no" scrolling="no" seamless src="${this.iframeSrc}"></iframe>
    `
    this.playerContainer.innerHTML = iframe;
  }
  destroyIframe() {
    this.playerContainer.innerHTML = '';
  }

  open() {
    this.close();
    this.createIframe()
    this.setAttribute('visible', '');
  }

  close() {
    if (this.hasAttribute('visible')) {
      this.removeAttribute('visible');
    }
    this.destroyIframe();
  }
}
customElements.define('amld-podcast-episode-player', PodcastEpisodePlayer);

document.addEventListener("turbolinks:load", function () {
  document.addEventListener('click', (e) => {
    if (e.target.matches('amld-podcast-episode-player')){
      document.querySelectorAll('amld-podcast-episode-player').forEach((el) => {el.close()});
      e.target.open();
    };
  }, false);
});
