document.addEventListener("turbolinks:load", function () {
  document.getElementById("open_touch_menu_btn").addEventListener("click", function (e) {
    document.body.classList.add('mobile_menu_visible');
    e.preventDefault();
  });
  document.getElementById("close-touch-menu-btn").addEventListener("click", function (e) {
    document.body.classList.remove('mobile_menu_visible');
    e.preventDefault();
  });

  document.addEventListener("turbolinks:before-cache", function () {
    if (!document.body.classList.contains('hidden')) {
      document.body.classList.remove('mobile_menu_visible');
    }
  })
});

